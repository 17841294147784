<template>
    <div>
        <el-card>
            <el-switch v-model="xianshiqiehuan" active-text="列表" inactive-text="块" @change="saveSwitchValue"></el-switch>
            <!-- <span style="float: right;">负载计算百分比为节点总带宽除以当前上传带宽，理论上是准确的占用。在线隧道数据为节点总端口占用数。数据每隔3秒刷新一次。</span> -->
            <el-alert style="margin-top: 10px;" title="节点负载" type="info"
                description="负载计算百分比为节点总带宽除以当前上传带宽，理论上是准确的占用。在线隧道数据为节点总端口占用数。数据每隔3秒刷新一次。" show-icon>
            </el-alert>
        </el-card>
        <el-row v-if="!xianshiqiehuan" :gutter="20">
            <el-col v-for="(item, index) in apiData" :key="index" :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="top">
                <el-card shadow="hover">
                    <div class="progress-container">
                        <el-progress type="circle" :percentage="item.server_bandwidth_usage"
                            :color="customColors"></el-progress>
                        <el-divider direction="vertical"></el-divider>
                        <el-descriptions class="margin-top" :title="item.name" :column="2" direction="vertical">
                            <el-descriptions-item label="上传流量">{{ formatData(item.NetOutTransfer) }}</el-descriptions-item>
                            <el-descriptions-item label="下载流量">{{ formatData(item.NetInTransfer) }}</el-descriptions-item>
                            <el-descriptions-item label="隧道总数">{{ item.dkzy }}</el-descriptions-item>
                            <el-descriptions-item label="状态">
                                <el-row :gutter="20">
                                    <el-col :span="9">
                                        <el-tag :type="getTagType(item.online_status, item.server_bandwidth_usage)">
                                            {{ getTagContent(item.online_status, item.server_bandwidth_usage) }}
                                        </el-tag>
                                    </el-col>
                                    <el-col v-if="item.nodegroup === 'vip'" :span="9">
                                        <el-tag type="warning" effect="plain">VIP</el-tag>
                                    </el-col>
                                </el-row>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div>
            <el-table v-if="xianshiqiehuan" max-height="100%" v-loading="loading" :data="tableData" stripe
                style="width: 100%; margin-top: 10px;">
                <el-table-column prop="name" label="节点名称"></el-table-column>
                <el-table-column prop="NetInTransfer" label="下载流量">
                    <template slot-scope="scope">
                        <el-tag type="info" style="color: rgba(0, 0, 0, 0.9);">
                            {{ formatData(scope.row.NetInTransfer) }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="NetOutTransfer" label="上传流量">
                    <template slot-scope="scope">
                        <el-tag type="info" style="color: rgba(0, 0, 0, 0.9);">
                            {{ formatData(scope.row.NetOutTransfer) }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="server_bandwidth_usage" width='300px' label="节点负载">
                    <template slot-scope="scope">
                        <el-progress :percentage="scope.row.server_bandwidth_usage" :color="customColors"></el-progress>
                    </template>
                </el-table-column>
                <el-table-column prop="dkzy" label="隧道数量" width="100px">
                    <template slot-scope="scope">
                        {{ scope.row.dkzy }}
                    </template>
                </el-table-column>
                <el-table-column label="节点标签" width='280px'>
                    <template slot-scope="scope">
                        <!-- <el-tag :type="getTagType(scope.row.online_status, scope.row.server_bandwidth_usage)">
                            {{ getTagContent(scope.row.online_status, scope.row.server_bandwidth_usage) }}
                        </el-tag> -->
                        <el-alert :closable="false"
                            :title="getTagContentlb(scope.row.online_status, scope.row.server_bandwidth_usage)"
                            :type="getTagTypelb(scope.row.online_status, scope.row.server_bandwidth_usage)" show-icon>
                        </el-alert>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            xianshiqiehuan: false,
            formInline: {
                time: '',
            },
            tableData: [],
            customColors: [
                { color: '#6f7ad3', percentage: 20 },
                { color: '#1989fa', percentage: 40 },
                { color: '#5cb87a', percentage: 60 },
                { color: '#e6a23c', percentage: 80 },
                { color: '#f56c6c', percentage: 100 }
            ],
            apiData: [],
            loading: false,
        };
    },
    methods: {
        saveSwitchValue() {
            document.cookie = `xianshiqiehuana=${this.xianshiqiehuan}`; // 将选择的值保存到Cookie中
        },
        loadSwitchValue() {
            const cookies = document.cookie.split(";"); // 获取所有的Cookie
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith("xianshiqiehuana=")) {
                    const value = cookie.substring("xianshiqiehuana=".length, cookie.length);
                    this.xianshiqiehuan = value === "true"; // 加载Cookie中保存的值，并设置给开关组件
                    break;
                }
            }
        },

        fetchDataFromAPI() {
            fetch('https://cf-v1.uapis.cn/api/status.php')
                .then(response => response.json())
                .then(data => {
                    this.apiData = data;
                    this.tableData = data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
        },
        calculatePercentage(netIn, netOut) {
            return (netIn / Math.max(netIn, netOut)) * 100;
        },
        formatData(value) {
            if (value < 1024) {
                return value.toFixed(2) + ' Byte';
            } else if (value < 1024 * 1024) {
                const kilobytes = value / 1024;
                return kilobytes.toFixed(2) + ' KiB';
            } else if (value < 1024 * 1024 * 1024) {
                const megabytes = value / (1024 * 1024);
                return megabytes.toFixed(2) + ' MiB';
            } else if (value < 1024 * 1024 * 1024 * 1024) {
                const gigabytes = value / (1024 * 1024 * 1024);
                return gigabytes.toFixed(2) + ' GiB';
            } else {
                const terabytes = value / (1024 * 1024 * 1024 * 1024);
                return terabytes.toFixed(2) + ' TiB';
            }
        },

        getTagTypelb(onlineStatus, bandwidthUsage) {
            if (!onlineStatus) {
                return 'info';
            } else {
                if (bandwidthUsage >= 0 && bandwidthUsage <= 70) {
                    return 'success';
                } else if (bandwidthUsage > 70 && bandwidthUsage <= 100) {
                    return 'warning';
                } else if (bandwidthUsage > 100) {
                    return 'error';
                }
            }
        },
        getTagContentlb(onlineStatus, bandwidthUsage) {
            if (!onlineStatus) {
                return '此节点已离线';
            } else {
                if (bandwidthUsage >= 0 && bandwidthUsage <= 70) {
                    return '此节点正在正常运行';
                } else if (bandwidthUsage > 70 && bandwidthUsage <= 100) {
                    return '此节点运行堵塞';
                } else if (bandwidthUsage > 100) {
                    return '此节点正在超负荷运行';
                }
            }
        },

        getTagType(onlineStatus, bandwidthUsage) {
            if (!onlineStatus) {
                return 'info';
            } else {
                if (bandwidthUsage >= 0 && bandwidthUsage <= 25) {
                    return 'default';
                } else if (bandwidthUsage > 25 && bandwidthUsage <= 50) {
                    return 'success';
                } else if (bandwidthUsage > 50 && bandwidthUsage <= 75) {
                    return 'warning';
                } else if (bandwidthUsage > 75) {
                    return 'danger';
                }
            }
        },
        getTagContent(onlineStatus, bandwidthUsage) {
            if (!onlineStatus) {
                return '离线';
            } else {
                if (bandwidthUsage >= 0 && bandwidthUsage <= 25) {
                    return '空闲';
                } else if (bandwidthUsage > 25 && bandwidthUsage <= 50) {
                    return '正常';
                } else if (bandwidthUsage > 50 && bandwidthUsage <= 75) {
                    return '忙碌';
                } else if (bandwidthUsage > 75 && bandwidthUsage <= 100) {
                    return '满载';
                } else if (bandwidthUsage > 100) {
                    return '超负荷';
                }
            }
        }
    },
    created() {
        this.fetchDataFromAPI();
        this.loadSwitchValue(); // 页面加载时加载Cookie中保存的值

        this.interval = setInterval(() => {
            this.fetchDataFromAPI();
        }, 3000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
};
</script>
  
<style>
.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress-text {
    margin-left: 10px;
}

.top {
    margin-top: 20px;
}
</style>